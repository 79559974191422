<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        notification.type == "user_update"
          ? t("notification_types.user_update")
          : notification.type == "move"
          ? t("notification_types.move")
          : notification.type == "alteration"
          ? t("notification_types.alteration")
          : notification.type == "meter"
          ? t("notification_types.meter")
          : t("notification_types.request")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card mb-4 p-4">
      <div v-if="notification.type == 'move'">
        <div
          class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div>
            <label class="block">{{ t("labels.estate") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <div v-else>
              <span>{{ estate.name }}</span>
            </div>
          </div>
          <div>
            <label class="block">{{ t("labels.building") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <div v-else>
              <span>{{
                getBuilding(
                  [estate],
                  notification.estateId,
                  notification.buildingId
                )
              }}</span>
            </div>
          </div>
          <div>
            <label class="block">{{ t("labels.apartment") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <div v-else>
              <span>{{ notification.apartment }}</span>
            </div>
          </div>
          <div>
            <label class="block">{{ t("labels.move_date") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              getLocaleDate(locale, notification?.moveDate)
            }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.move_type") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ t(`move_types.${notification?.moveType}`) }}</span>
          </div>
          <div v-if="notification?.moveType === 'in'">
            <label class="block">{{ t("labels.inhabited") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              notification?.inhabited ? t("labels.yes") : t("labels.no")
            }}</span>
          </div>
          <div v-else>
            <label class="block">{{ t("labels.is_empty") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              notification?.empty ? t("labels.yes") : t("labels.no")
            }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.declarant") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ t(`roles.${notification?.declarant}`) }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.is_moving") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              notification?.isMoving ? t("labels.yes") : t("labels.no")
            }}</span>
          </div>
          <div v-if="notification?.moveType === 'in'">
            <label class="block">{{ t("labels.address_old") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ notification?.addressOld }}</span>
          </div>
          <div v-else>
            <label class="block">{{ t("labels.address_new") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ notification?.addressNew }}</span>
          </div>
          <hr
            class="col-span-1 my-4 border-slate-300 sm:col-span-3 md:col-span-2 lg:col-span-3"
          />
          <div>
            <label class="block">{{ t("labels.movers") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <ul v-else>
              <li v-for="(mover, index) of notification?.movers" :key="index">
                {{ mover.value }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-else
        class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div>
          <label class="block">{{ t("labels.topic") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.topic }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.apartment") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.apartment }}</span>
          </div>
        </div>
        <div>
          <label class="block">{{ t("labels.date") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <span v-else>{{
            getLocaleDate(locale, notification.createdAt)
          }}</span>
        </div>
        <div>
          <label class="block">{{ t("labels.content") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <span>{{ notification.content }}</span>
          </div>
        </div>
        <div v-if="notification.image" class="col-span-3">
          <label class="block">{{ t("labels.image") }}</label>
          <span v-if="waiting">
            <skeleton class="h-5 w-full" />
          </span>
          <div v-else>
            <a
              class="underline"
              href="javascript:;"
              @click="openFile(notification.image)"
              >{{ t("labels.image") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getBuilding } from "../utils/estateUtil";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const estate = computed(() => store.state.estate.estate);
    const file = computed(() => store.state.file.file);
    const notification = computed(() => store.state.notification.notification);

    const waiting = computed(() => {
      if (store.state.notification.waiting) return true;
      if (store.state.authentication.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "startDate",
        label: "start_date",
      },
      {
        key: "endDate",
        label: "end_date",
      },
      {
        key: "status",
        label: "status",
      },
    ]);
    const searchFields = ref(["name", "startDate", "endDate", "status"]);
    const sortFields = ref(["name", "startDate", "endDate", "status"]);

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    onMounted(() => {
      store.dispatch(
        "notification/getNotification",
        route.params.notificationId
      );
    });

    watch(
      () => file.value,
      (file) => {
        if (file) {
          window.open(file, "_blank");
        }
      }
    );

    return {
      estate,
      fields,
      getBuilding,
      getLocaleDate,
      locale,
      notification,
      openFile,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
